import React from 'react'
import NrcmsHeader from './nrcmsHeader'
import NrcmsTopPage from './nrcmsTopPage'

function nrcms() {
  return (
    <>
           <NrcmsHeader/>
           <NrcmsTopPage/>
        </>
  )
}

export default nrcms