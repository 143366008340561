import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { url } from "../urls";

const Login = () => {
  const [msg, setMsg] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const adminLoginHandler = useMutation(
    async (data) => {
      console.log("Data sent to API:", data);
      const response = await axios.post(`${url}/authentication/login`, {
        adminEmail: data.email,  
        password: data.password,
      });
      console.log("API response:", response);
      return response.data;
    },
    {
      onSuccess: (res) => {
        console.log("Successful response:", res);
        if (res.status === 200) {
          Cookies.set("giks_token", res.token, { expires: 7 });
          toast.success("Login successful!");
          navigate("/dashboard");
        } else {
          setMsg("Login failed. Please try again.");
          toast.error("Login failed. Please try again.");
        }
      },
      onError: (error) => {
        console.error("Error response:", error);
        setMsg("Login failed. Please try again.");
        toast.error("Login failed. Please try again.");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters long")
        .required("Password is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      adminLoginHandler.mutate(values, {
        onSettled: () => {
          setLoading(false);
        },
      });
    },
  });

  return (
    <>
      <section className="contact-single">
        <div className="container">
          <div className="row mob-col-reverse">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="contact-single-left">
                <h1 className="common-heading my-3 my-4">
                  Innovating Technologies
                </h1>
                <p className="common-subheading mt-4 my-5">
                  Delivering Solutions Offering customized and innovative
                  solutions for scalable enterprises.
                  <br /> to schedule an appointment?
                </p>
                <p className="common-subheading mt-4">
                  <br />
                  We specialize in Providing software development services that
                  cater to the unique needs and requirements for the
                  organizations.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="contact-single-right">
                <h1 className="common-heading text-center my-3">Login</h1>
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-12 col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          E-mail
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="email"
                          {...formik.getFieldProps('email')}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <p className="text-danger">{formik.errors.email}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6">
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          autoComplete="off"
                          name="password"
                          {...formik.getFieldProps('password')}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <p className="text-danger">{formik.errors.password}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <button
                        className="common-btn mt-4 d-inline-block bg-transparent"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>

                    {loading ? (
                      <div>
                        <div className="overlay"></div>
                        <div className="position-absolute top-50 start-50 translate-middle loader-parent">
                          <div className="loader-parent">
                            <span className="loader">loading...</span>
                          </div>
                        </div>
                      </div>
                    ) : msg ? (
                      <div className="mt-4">
                        <p className="text-center">{msg}</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default Login;
